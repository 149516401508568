import React, {
  createContext,
  useState,
  useEffect,
  ReactNode,
  useContext,
} from "react";
import { DecodeTokenJwt } from "../Assets/Helpers";
import Services from "../Services/DataOceanServices";
import LoadingPage from "../Assets/PageLoading/PageLoading";

const AuthContext = createContext({});

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [empresaAtiva, setEmpresaAtiva] = useState(null);
  const [planoAtivo, setPlanoAtivo] = useState({});
  const [empresas, setEmpresas] = useState([]);
  const [loading, setLoading] = useState(false);
  const [onboarding, setOnboarding] = useState(false);

  //@collaps

  useEffect(async () => {
    const sessionToken = JSON.parse(sessionStorage.getItem("user"));
    async function loadStorage() {
      let userData = DecodeTokenJwt(sessionToken.token);
      let empresaId = "";
      let empresas = [];
      let empresaAtiva = {};

      const empresaSession = sessionStorage.getItem("empresas");
      if (empresaSession == null) {
        sessionStorage.setItem("empresas", JSON.stringify(userData.empresas));
      }

      if (typeof userData.empresas == "string") {
        empresaId = parseInt(userData.empresas);
      } else {
        empresaId = parseInt(userData.empresas[0]);
      }

      if (typeof userData.empresas == "string") {
        let empresa = await Services.Empresa.getEmpresaById(empresaId);
        empresaAtiva = empresa;
        empresas.push(empresa);
      } else {
        for (const id of userData.empresas) {
          let empresa = await Services.Empresa.getEmpresaById(id);
          if (empresa.empresaId == empresaId) {
            empresaAtiva = empresa;
          }
          empresas.push(empresa);
        }
      }

      let _planoAtivoId = await Services.Empresa.GetPlanoEmpresa(empresaId);
      let _planoAtivo =
        (await Services.Planos.getPlanoById(_planoAtivoId?.id)) || {};
      let _planos = await Services.Planos.getPlanos();

      switch (_planos.map((p) => p.id).indexOf(_planoAtivo.id)) {
        case 1:
          _planoAtivo.planoColor = "var(--Color-Complementary_Gr)";
          break;
        case 2:
          _planoAtivo.planoColor = "var(--Color-Secondary_0)";
          break;
        case 3:
          _planoAtivo.planoColor = "var(--Color-Complementary_Pu)";
          break;
        default:
          _planoAtivo.planoColor = "var(--Color-Primary_-1)";
          break;
      }

      if(! await setConsolidadoEmpresa()){
        setEmpresaAtiva(empresaAtiva);
      }
      setPlanoAtivo(_planoAtivo);
      setEmpresas(empresas);
      setUser(userData);
      setLoading(false);
    }

    if (sessionToken != null) {
      const date = Date.parse(new Date());
      if (sessionToken && Date.parse(sessionToken.expiration) > date) {
        setLoading(true);
        loadStorage();
      }
    }
  }, []);

  async function singIn(token, onboarding = false) {
    setLoading(true);
    let userData = DecodeTokenJwt(token);
    let empresaId = "";
    let empresas = [];
    let empresaAtiva = {};

    if (typeof userData.empresas == "string") {
      empresaId = parseInt(userData.empresas);
    } else {
      empresaId = parseInt(userData.empresas[0]);
    }

    if (typeof userData.empresas == "string") {
      let empresa = await Services.Empresa.getEmpresaById(empresaId);
      empresaAtiva = empresa;
      empresas.push(empresa);
    } else {
      for (const id of userData.empresas) {
        let empresa = await Services.Empresa.getEmpresaById(id);
        if (empresa.empresaId == empresaId) {
          empresaAtiva = empresa;
        }
        empresas.push(empresa);
      }
    }

    let resultTelas = await Services.PermissaoTelas.ObterPermissoes(
      userData.userId
    );
    sessionStorage.setItem("telas", JSON.stringify(resultTelas));

    const onboardingExist = JSON.parse(sessionStorage.getItem("onboarding"));

    if (onboarding == true) {
      const onboardingData = {
        userId: 12,
        onboarding: true,
      };

      //sessionStorage.setItem('onboarding', JSON.stringify(onboardingData));
      setOnboarding(true);
    }

    if (onboardingExist) {
      /* if(onboardingExist.userId == userData) {
        setOnboarding(true);
      } */
    }

    let _planoAtivoId = await Services.Empresa.GetPlanoEmpresa(empresaId);
    let _planoAtivo =
      (await Services.Planos.getPlanoById(_planoAtivoId?.id)) || {};
    let _planos = await Services.Planos.getPlanos();

    switch (_planos.map((p) => p.id).indexOf(_planoAtivo.id)) {
      case 1:
        _planoAtivo.planoColor = "var(--Color-Complementary_Gr)";
        break;
      case 2:
        _planoAtivo.planoColor = "var(--Color-Secondary_0)";
        break;
      case 3:
        _planoAtivo.planoColor = "var(--Color-Complementary_Pu)";
        break;
      default:
        _planoAtivo.planoColor = "var(--Color-Primary_-1)";
        break;
    }

    if(! await setConsolidadoEmpresa(empresas)){
      setEmpresaAtiva(empresaAtiva);
    }

    //setEmpresaAtiva(empresaAtiva);
    setPlanoAtivo(_planoAtivo);
    setEmpresas(empresas);
    setUser(userData);
    setLoading(false);
  }

  function alterarEmpresa(empresa) {
    setEmpresaAtiva(empresa);
  }

  async function setConsolidadoEmpresa() {
    let empresasTemp = await getEmpresasContext();

    for (let index = 0; index < empresasTemp.length; index++) {
      const element = empresasTemp[index];
      if(element.empresaIdReferente == null && empresasTemp.length > 2){
        alterarEmpresa(element);
        return true;
      }
    }
    return false;
  }

  async function getEmpresasContext() {
    let empresas = await Services.Empresa.getEmpresasUsuarioApi("", 1, true);
    setEmpresas(empresas);

    return empresas;
  }

  async function addEmpresasSession(id) {
    const empresaSession = JSON.parse(sessionStorage.getItem("empresas"));
    const empresas = empresaSession;

    empresas.push(id);
    sessionStorage.setItem("empresas", JSON.stringify(empresas));
  }

  function necessitaOnboarding(bool) {
    setOnboarding(bool);
  }

  if (loading) {
    return (
      <div style={{ width: "100vw", height: "100vh" }}>
        <LoadingPage
          style={{ display: "flex", justifyContent: "center" }}
          className={""}
          css={"classLoaderCenter"}
          size={15}
          color={"#59534C"}
        />
      </div>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        empresaAtiva,
        planoAtivo,
        empresas,
        singIn,
        alterarEmpresa,
        necessitaOnboarding,
        onboarding,
        getEmpresasContext,
        addEmpresasSession,
        setConsolidadoEmpresa
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);

  return context;
}
